import React from 'react'
import './Footer.css';

export default function Footer() {
  return (
    <footer>
      <section className='footer-container flex'>
        <div className="flex contact">
        <p>©2024 by Rainier Tech Roots. Located in South Seattle.
        </p>
        </div>
        <div>
        <p>rainiertechroots@gmail.com</p>
        </div>
      </section>
    </footer>
  )
}

